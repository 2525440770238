<!-- start main Cintent -->
<div *ngIf="!Authenticated">
    <app-header></app-header>
    <router-outlet></router-outlet>
</div>


<mat-drawer-container class="example-container" *ngIf="Authenticated">
    <mat-drawer mode="side" opened style="width: 20%;">
        <app-side-bar></app-side-bar>
    </mat-drawer>
    <mat-drawer-content style="background-color: white; min-height: 100vh; width: 80%;">
        <app-header></app-header>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>

<!-- end main Cintent -->

<!-- start Footer -->
<app-footer></app-footer>
<!-- end Footer -->
<a class="back-to-top" (click)="backToTop()"><i class="ri-arrow-up-line"></i
></a>
