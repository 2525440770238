import { SignalrService } from './core/services/signalr.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { StorageService } from './core/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'UlendWeb';
  Authenticated: boolean = false;
  subscription: Subscription;

  constructor(
    private securityService: SecurityService,
    private signalrService: SignalrService,
    private storage: StorageService
  ) {
    this.Authenticated = this.securityService.IsAuthorized;
  }

  ngOnInit() {
    this.subscription = this.securityService.authenticationChallenge$.subscribe(
      (res) => {
        this.Authenticated = res;
      }
    );
    this.chechAuthentication();
  }

  private chechAuthentication() {
    if (this.Authenticated) {
      const id_token = localStorage.getItem('authorizationDataIdToken');
      let identityToken = id_token.substring(1);
      identityToken = identityToken.substring(0, id_token.length - 2);
      this.storage.storeToSession('authorizationDataIdToken', identityToken);
    }
  }
}
