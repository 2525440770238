<div class="d-none d-lg-block login-signup mx-1" *ngIf="!authenticated">
  <a class="border-right border-secondary px-2" (click)="login()"
    ><i class="icofont-user mr-2"></i>
    {{'login' | translate}}
  </a>
  <a class="px-2" [routerLink]="['/register/basic-info']"
    >{{'signUp' | translate}}</a
  >
</div>
<div class="dropdown" *ngIf="authenticated">
  
  <button
    class="dropdown-toggle"
    style="white-space: normal"
    id="userMenu"
    mat-button
  >
    {{userName}}
  </button>
  <mat-menu #menu="matMenu">
    <a class="dropdown-item" mat-menu-item> {{'changePassword'|translate}} </a>
    <a mat-menu-item class="dropdown-item"> {{'logout'|translate}} </a>
  </mat-menu>
</div>
