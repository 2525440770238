import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedHelper } from 'src/app/core/helpers/shared.helper';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { SecurityService } from 'src/app/core/services/security.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  headItems = [{
    link: '/',
    desc: 'home',
    isActive: false,
    queryParams: '',
    linkType: 'noneType'
  },
  {
    link: '/register/instructions',
    desc: 'lender',
    isActive: false,
    queryParams: 'lender',
    linkType: 'instructions'
  },
  {
    link: '/register/instructions',
    desc: 'borrower',
    isActive: false,
    queryParams: 'borrower',
    linkType: 'instructions'
  },
  {
    link: '/successStories',
    desc: 'stories',
    isActive: false,
    queryParams: '',
    linkType: 'noneType'
  },
  {
    link: '/static/about',
    desc: 'aboutUs',
    isActive: false,
    queryParams: '',
    linkType: 'noneType'
  },
  {
    link: '/static/faq',
    desc: 'help',
    isActive: false,
    queryParams: '',
    linkType: 'noneType'
  }];
  otherLanguage: string;
  isAuthorized: boolean;
  name;
  userType: UserType;
  constructor(
    public router: Router,
    public sharedHelper: SharedHelper,
    public securityService: SecurityService
  ) { }

  ngOnInit(): void {
    this.isAuthorized = this.securityService.IsAuthorized;
    this.userType = this.securityService.currentUser() == null ? null : +this.securityService.currentUser().role as UserType;
    this.setInialLanguage();
    this.setUserData();
  }
  setUserData() {
    if (this.securityService.IsAuthorized) {
      this.name = this.securityService.currentUser().preferred_username ?
        this.securityService.currentUser().preferred_username :
        this.securityService.currentUser().email;
    }
  }
  setInialLanguage() {
    this.otherLanguage = sessionStorage.getItem('lang') ?
      sessionStorage.getItem('lang') === 'ar-EG' ?
        'English' :
        'العربية' :
      'English';
  }
  isActive(i): boolean {
    if (this.headItems[i].link === this.router.url) {
      return true;
    }
    return false;
  }
  toggelMobileMenue(rout): void {
    document.body.classList.toggle('mobile-nav-active');
    if (rout) {
      if (rout === '/auth/login') {
        this.login();
        return;
      }
      this.router.navigate([rout]);
    }
  }
  logout() {
    localStorage.removeItem('phoneVerified');
    this.securityService.Logoff();
  }
  login() {
    this.securityService.Authorize();
  }
}
