import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  private storage: any;
  private session: any;
  constructor() {
    this.storage = localStorage;
    this.session = sessionStorage;
  }

  public retrieve(key: string): any {
    let item = this.storage.getItem(key);

    if (item && item !== 'undefined') {
      return JSON.parse(this.storage.getItem(key));
    }
    return;
  }

  public store(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public clear(key: string) {
    this.storage.removeItem(key);
  }


  public retrieveFromSession(key: string): any {
    let item = this.session.getItem(key);

    if (item && item !== 'undefined') {
      return JSON.parse(this.session.getItem(key));
    }
    return;
  }

  public storeToSession(key: string, value: any) {
    this.session.setItem(key, JSON.stringify(value));
  }

  public clearFromSession(key: string) {
    this.session.removeItem(key);
  }




}
