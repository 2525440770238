import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/core/services/security.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  isLoggedIn: boolean;
  constructor(
    public router: Router,
    private securityService: SecurityService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.securityService.IsAuthorized;
  }

}
