<!-- End Footer -->
<!-- <footer id="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-sm-12 col-md-6 d-flex footer-contact">
                    <div class="footer-logo mr-4">
                        <img src="../assets/img/logo/logo.svg" alt="logo">
                    </div>
                    <div class="footer-contact-bootom">
                        <p>
                            {{'UlendAim'|translate}}
                        </p>
                        <div class="social-links mt-3">
                            <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                            <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                            <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                            <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-12 footer-links pl-100">
                    <h4>
                        {{'quick_links'|translate}}
                    </h4>
                    <ul>
                        <li *ngIf="!isLoggedIn"><a [routerLink]="['/register/instructions']" [queryParams]="{user: 'borrower' }" queryParamsHandling="userType">
                {{'brwr_gate'|translate}}
              </a></li>
                        <li *ngIf="!isLoggedIn"><a [routerLink]="['/register/instructions']" [queryParams]="{user: 'lender'}" queryParamsHandling="userType">
                {{'lndr_gate'|translate}}
              </a></li>
                        <li><a [routerLink]="['/static/faq']">{{'help' | translate}}</a></li>
                        <li><a href="https://www.nayifat.com/" target="_blank">{{'nayfat' | translate}}</a></li>
                        <li><a [routerLink]="['/static/about']">{{'aboutUs' | translate}}</a></li>
                    </ul>
                </div>

                <div class="col-lg-4 col-sm-12 footer-links contact-links">
                    <h4 class="link" (click)="router.navigate(['/static/contact-us'])">
                        {{'contact_us'|translate}}
                    </h4>
                    <ul>
                        <li>{{'insd_ksa_phone'|translate}}:<a href="tel:920035666">920035666</a></li>
                        <li>{{'outnsd_ksa_phone'|translate}}: <a href="tel:00966115107220">00966115107220</a></li>
                        <li>{{'cmplnt_phn'|translate}}: <a href="tel:920035666">920035666</a></li>
                        <li>{{'free_phn'|translate}}: <a href="tel:8001000088">8001000088</a></li>
                        <li>{{'email'|translate}}: <a href="mailto:info@ulend.sa">info@ulend.sa</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container footer-bottom clearfix">
        <div class="copyright">
            <label>
        {{'copyrights'| translate}} © 2020 {{'allRights'|translate}} <a class="primary-link" href="https://ulend.sa"
          target="_blank"> {{'ulndWebsite'|translate}}</a>
      </label>

        </div>
        <div class="copyright-list float-right">
            <ul class="d-flex no-list-style nopadding">
                <li>
                    <label>
            <a class="border-right border-secondary px-2 primary-link" routerLink="/static/term-of-use">
              {{'trm_cndion'|translate}}
            </a>
          </label>
                </li>
                <li>

                    <label>
            <a class="border-right border-secondary px-2 primary-link" routerLink="/static/privacy-policy">
              {{'prvcy_plcy'|translate}}
            </a>
          </label>
                </li>

            </ul>
        </div>
    </div>
</footer> -->

<!-- End Footer -->
<footer id="footer" class="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-sm-12 col-md-6 footer-contact">
                    <div class="footer-logo mb-4">
                        <img src="../assets/img/logo/logo-AR-white.svg" alt="logo">
                    </div>
                    <div class="footer-contact-bootom">
                        <p>
                            منصة سعودية متخصصة لتقديم حلول مالية شاملة تحت اشراف البنك المركزي السعودي بتصريح رقم
                        </p>

                        <div class="iconPay mt-3">
                            <img src="../../../../assets/img/new/5.svg" alt="">
                            <img src="../../../../assets/img/new/6.svg" alt="">
                            <img src="../../../../assets/img/new/7.svg" alt="">
                            <img src="../../../../assets/img/new/8.svg" alt="">
                            <img src="../../../../assets/img/new/9.svg" alt="">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-12 footer-links pl-100">
                    <h4>
                        مصادر مهمة
                    </h4>
                    <ul>
                        <!-- <li><a routerLink="/static/term-of-use">سياسة الخصوصية</a></li> -->
                        <!-- <li><a routerLink="/static/privacy-policy" target="_blank">الشروط والاحكام </a></li> -->
                    </ul>
                </div>

                <div class="col-lg-2 col-sm-12 footer-links pl-100">
                    <h4>
                        الدعم الفنى
                    </h4>
                    <ul>
                        <li><a [routerLink]="['/static/faq']"> الاسئلة الشائعة </a></li>
                    </ul>
                </div>
                <div class="col-lg-2 col-sm-12 footer-links pl-100">
                    <h4>
                        تعرف علينا
                    </h4>
                    <ul>
                        <li><a [routerLink]="['/static/about']"> من نحن </a></li>
                        <li><a (click)="router.navigate(['/static/contact-us'])"> اتصل بنا  </a></li>
                        <li><a href="#"> الشركاء </a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom clearfix">
        <div class="container">
            <div class="copyright">
                <div class="social-links">
                    <a href="#" class="facebook">
                        <img src="../../../../assets/img/new/facebook.svg" alt="">
                    </a>
                    <a href="#" class="twitter">
                        <img src="../../../../assets/img/new/twitter.svg" alt="">
                    </a>
                    <a href="#" class="linkedin">
                        <img src="../../../../assets/img/new/linkedin.svg" alt="">
                    </a>
                </div>
            </div>
        </div>

    </div>
</footer>