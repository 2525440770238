import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDatepickerI18n,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbCalendarIslamicUmalqura,
  NgbDate,
} from '@ng-bootstrap/ng-bootstrap';

import {
  CustomAdapter,
  CustomDateParserFormatter,
} from 'src/app/core/services/custom-adapter-and-formatter.service';
import { IslamicI18nService } from 'src/app/core/services/islamic-calender-i18n.service';

@Component({
  selector: 'app-hijri-calender',
  templateUrl: './hijri-calender.component.html',
  styleUrls: ['./hijri-calender.component.scss'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: IslamicI18nService },
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class HijriCalenderComponent implements OnInit, OnChanges {
  model: any;
  @Output() dateSelect: EventEmitter<string> = new EventEmitter<string>();
  sperator = '-';
  @Input() selectedDate: string;
  @Input() label: string;
  @Input() maxDateDifference: { year: number; month: number; day: number };
  @Input() maxDate: NgbDate;
  @Input() minDateDifference: { year: number; month: number; day: number };
  @Input() minDate: NgbDate;
  @Input() isRequried: boolean;
  @Input() positionTarget: boolean;

  constructor(private calendar: NgbCalendar) {}
  ngOnInit(): void {
    this.setMinAndMax();
    this.setValue();
  }
  setMinAndMax() {
    const today = this.calendar.getToday();
    if (!this.maxDateDifference) {
      if (!this.maxDate) {
        this.maxDate = new NgbDate(1600, 12, 29);
      }
    } else {
      const y = this.calendar.getNext(today, 'y', this.maxDateDifference.year);
      const m = this.calendar.getNext(y, 'y', this.maxDateDifference.month);
      this.maxDate = this.calendar.getNext(m, 'y', this.maxDateDifference.day);
    }
    if (!this.minDateDifference) {
      if (!this.minDate) {
        this.minDate = new NgbDate(1300, 1, 1);
      }
    } else {
      const y = this.calendar.getPrev(today, 'y', this.minDateDifference.year);
      const m = this.calendar.getPrev(y, 'y', this.minDateDifference.month);
      this.minDate = this.calendar.getPrev(m, 'y', this.minDateDifference.day);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.model = changes.selectedDate?.currentValue;
  }
  setValue() {
    if (this.selectedDate) {
      const dateParams = this.selectedDate.split(this.sperator);
      this.model = new NgbDate(
        parseInt(dateParams[2], 10),
        parseInt(dateParams[1], 10),
        parseInt(dateParams[0], 10)
      );
      const day =
        this.model.day < 10
          ? '0' + String(this.model.day)
          : String(this.model.day);
      const month =
        this.model.month < 10
          ? '0' + String(this.model.month)
          : String(this.model.month);
      const year = String(this.model.year);
      this.model = day + this.sperator + month + this.sperator + year;
    } else {
      this.model = null;
    }
  }
  onDateSelect(date: NgbDate) {
    const day = date.day < 10 ? '0' + String(date.day) : String(date.day);
    const month =
      date.month < 10 ? '0' + String(date.month) : String(date.month);
    const year = String(date.year);
    this.dateSelect.emit(day + this.sperator + month + this.sperator + year);
  }
}
