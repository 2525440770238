<div id="profile-sidebar">
    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        <!-- <h1 class="logo text-center my-5" id="logoImage"></h1> -->
        <a routerLink="/" class="text-center mt-2 mb-5">
            <img src="../../../../assets/img/logo/logo-AR.svg" alt="Ulenda">
        </a>
        <ul *ngFor="let command of commands; index as i" class="list-unstyled">
            <li *ngIf="command.child.length == 0">
                <a routerLink="{{command.link}}" [class.active]="isActive(command.link)?true:null" class="nav-link" id="command.id" data-toggle="pill" role="tab" aria-controls="v-pills-home" aria-selected="true">
                    <img src="{{command.image}}" alt="{{command.text}}"> {{ command.text | translate}}
                </a>
            </li>
            <!-- when child links that appeare just a title  -->
            <li *ngIf="command.child.length !== 0" (click)="displayChild(i)">
                <span class="nav-link" id="command.id" data-toggle="pill" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <img src="{{command.image}}" alt="{{command.text}}">
                        {{ command.text | translate}}
                    </span>
            </li>
            <!-- handel child links -->
            <div class="childSubLinks" *ngIf="command.child.length > 0 && command.isChildOpen">
                <ul *ngFor="let child of command.child; index as i" class="list-unstyled">
                    <li>
                        <a routerLink="{{child.link}}" [class.active]="isActive(child.link)?true:null" class="nav-link" id="child.id" data-toggle="pill" role="tab" aria-controls="v-pills-home" aria-selected="true">
                            <img src="{{child.image}}" alt="{{child.text}}"> {{ child.text | translate}}
                        </a>
                    </li>
                </ul>
            </div>
        </ul>
    </div>
</div>