import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { IPagingInfo } from '../models/common/paging.model';

@Injectable({
  providedIn: 'root'
})
export class SharedHelper {
  constructor() { }

  onPaginatorChanged(event): IPagingInfo {
    return {
      isAscending: true,
      orderBy: '',
      pageNumber: event.pageIndex,
      pageSize: event.pageSize
    };
  }
  changeLanguage() {
    sessionStorage.getItem('lang') === 'ar-EG' ?
      sessionStorage.setItem('lang', 'en-US') :
      sessionStorage.setItem('lang', 'ar-EG');
    window.location.reload();
  }
  getArrayFromEnum(enumClass) {
    const enumValues = Object.values(enumClass);
    const newLength = enumValues.length / 2;
    return enumValues
      .map((y, i) => {
        return {
          description: String(y),
          id: +enumValues[i + (newLength)]
        };
      })
      .slice(0, newLength);
  }
  datesAdder(date: NgbDate, years: number, months: number, days: number) {
    if (date) {
      return new NgbDate(
        date.year + years,
        date.month + months,
        date.day + days
      );
    }
  }
}
