<!-- <header *ngIf="!isAuthorized" id="header" class="fixed-top" [class.fixed-top]="router.url === '/' ? true : null">
    <div class="container d-flex align-items-center">
        <h1 class="logo">
            <a routerLink="/">
                <img src="assets/img/logo/logo.svg" alt="Ulenda" />
            </a>
        </h1>
        <nav class="nav-menu d-none d-lg-block nav-menu-item">
            <ul class="justify-content-md-center">
                <li class="nav-menu-item-member" [class.active]="isActive(i) ? true : null" *ngFor="let item of headItems; index as i">
                    <a *ngIf="item.linkType === 'instructions'" routerLink="{{ item.link }}" [queryParams]="{ user:  item.queryParams}">
            {{ item.desc | translate }}
          </a>
                    <a *ngIf="item.linkType === 'noneType'" routerLink="{{ item.link }}">
            {{ item.desc | translate }}
          </a>
                </li>
            </ul>
            <div class="d-lg-none login-signup">
                <a class="nav-menu-item-member mx-2" (click)="login()">{{
          "login" | translate
          }}</a>
                <a [routerLink]="['/register/instructions']" [queryParams]="{ user: 'lender' }" class="nav-menu-item-member">{{
          "signUp" | translate }}</a>
            </div>
            <div class="d-lg-none language header-item">
                <a class="header-item" class="lang-ar" (click)="sharedHelper.changeLanguage()">{{ otherLanguage }}</a>
            </div>
        </nav>
        <div class="d-none d-lg-block login-signup" *ngIf="!authenticated">
            <a class="header-item" class="lang-ar language" (click)="sharedHelper.changeLanguage()">{{ otherLanguage }}</a>
            <a class="border-right border-secondary px-1" (click)="login()"><i class="icofont-user mr-2"></i>
        {{'login' | translate}}
      </a>
            <a class="px-1" [routerLink]="['/register/basic-info']">{{'signUp' | translate}}</a>
        </div>
    </div>

    <button (click)="toggelMobileMenue(null)" type="button" class="mobile-nav-toggle d-lg-none">
    <i class="icofont-navigation-menu"> </i>
  </button>
    <nav class="mobile-nav d-lg-none">
        <ul>
            <li *ngFor="let item of headItems; index as i">
                <a (click)="toggelMobileMenue(item.link)" class="header-item">
          {{ item.desc | translate }}
        </a>
            </li>
            <li>
                <a (click)="sharedHelper.changeLanguage()" class="header-item">
          {{ otherLanguage }}
        </a>
                <app-identity></app-identity>
            </li>
        </ul>
        <div class="d-lg-none login-signup">
            <a (click)="toggelMobileMenue('/auth/login')">
        {{ "login" | translate }}
      </a>
            <a (click)="toggelMobileMenue('/register/instructions')">
        {{ "signUp" | translate }}
      </a>
        </div>
    </nav>

</header>



<header id="header" class="profile-header" *ngIf="isAuthorized">
    <div class="container">
        <div class="d-flex w-100">
            <button mat-button class="lang-ar" (click)=sharedHelper.changeLanguage()>
        {{otherLanguage}}
      </button>
            <div class="dropdown">
                <button class="dropdown-toggle" style="white-space: normal;" id="userMenu" mat-button [matMenuTriggerFor]="menu">
          {{name}}
        </button>
                <mat-menu #menu="matMenu">
                    <a class="dropdown-item" mat-menu-item routerLink="/profile">
            {{'myAccount'|translate}}
          </a>
                    <a mat-menu-item class="dropdown-item" (click)="logout()">
            {{'logout'|translate}}
          </a>
                </mat-menu>
            </div>
        </div>
    </div>
</header> -->

<!-- <header *ngIf="!isAuthorized" id="header" class="fixed-top" [class.fixed-top]="router.url === '/' ? true : null">
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent align-items-center text-dark">
      <div class="container">
        <a class="logo navbar-brand" routerLink="/">
          <img src="assets/img/logo/logo.svg" alt="Ulenda" />
        </a>
        <button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1">
          <span class="sr-only">
            Toggle navigation</span><span class="navbar-toggler-icon"></span>
          </button>
        <div class="collapse navbar-collapse" id="navcol-1">
          <ul class="nav navbar-nav text-right text-white ml-auto">
            <li class="nav-item" role="presentation" [class.active]="isActive(i) ? true : null"
              *ngFor="let item of headItems; index as i">
              <a  class="nav-link" *ngIf="item.linkType === 'instructions'" routerLink="{{ item.link }}" [queryParams]="{ user:  item.queryParams}">
              {{ item.desc | translate }}
            </a>
            <a class="nav-link" *ngIf="item.linkType === 'noneType'" routerLink="{{ item.link }}">
              {{ item.desc | translate }}
            </a>
            </li>
          </ul>
          <ul class="nav navbar-nav">
            <li class="nav-item my-auto" role="presentation">
              <a class="nav-link language" class="lang-ar px-2 align-items-center"
                (click)="sharedHelper.changeLanguage()">{{ otherLanguage }}</a>
            </li>
            <li class="nav-item pointer-cursor" role="presentation">
              <a class="nav-link border-right border-secondary px-1" (click)="login()">
                <i class="icofont-user mr-2"></i>{{'login' | translate}}
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link px-1" [routerLink]="['/register/basic-info']">{{'signUp' | translate}}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>

  <header id="header" class="profile-header w-lg-75 w-sm-100 d-sm-fixed" *ngIf="isAuthorized">
    <div class="container">
      <div class="row w-100 d-flex align-items-center">
        <div class="col-lg-2">
          <form id="search-form">
            <button class="lang-ar" (click)=sharedHelper.changeLanguage()>
              {{otherLanguage}}
            </button>
          </form>
        </div>
         <div class="dropdown">
          <button class="dropdown-toggle" style="white-space: normal;" id="userMenu" mat-button
            [matMenuTriggerFor]="menu">
            {{name}}
          </button>
          <mat-menu #menu="matMenu">
            <a class="dropdown-item" mat-menu-item routerLink="/auth/changePassword">
              {{'changePassword'|translate}}
            </a>
            <a mat-menu-item class="dropdown-item" (click)="logout()">
              {{'logout'|translate}}
            </a>
          </mat-menu>
        </div>
      </div>
    </div>
  </header> -->


<header *ngIf="!isAuthorized" id="header" class="fixed-top" [class.fixed-top]="router.url === '/' ? true : null">
    <div class="container justify-content-between d-flex align-items-center">
        <h1 class="logo">
            <a routerLink="/">
                <img src="assets/img/logo/logo-AR.svg" alt="ITIS" />
            </a>
        </h1>
        <div class="authenticated d-flex align-items-center">
            <nav class="nav-menu d-none d-lg-block">
                <ul class="justify-content-md-center">
                    <li class="nav-menu-item-member" [class.active]="isActive(i) ? true : null" *ngFor="let item of headItems; index as i">
                        <a *ngIf="item.linkType === 'instructions'" routerLink="{{ item.link }}" [queryParams]="{ user:  item.queryParams}">
          {{ item.desc | translate }}
        </a>
                        <a *ngIf="item.linkType === 'noneType'" routerLink="{{ item.link }}">
          {{ item.desc | translate }}
        </a>
                    </li>
                </ul>
                <!-- <div class="d-lg-none login-signup">
                    <a class="nav-menu-item-member mx-2" (click)="login()">{{
        "login" | translate
        }}</a>
                    <a [routerLink]="['/register/instructions']" [queryParams]="{ user: 'lender' }" class="nav-menu-item-member">{{
        "signUp" | translate }}</a>
                </div> -->
                <!-- <div class="d-lg-none language header-item">
                    <a class="header-item" class="lang-ar" (click)="sharedHelper.changeLanguage()">{{ otherLanguage }}</a>
                </div> -->
            </nav>
            <div class="contact-us">
                <a disabled matTooltip="جاري العمل علي التطبيق !" class="link"> حمل التطبيق </a>
                <!-- <button type="button" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                  Tooltip on top
                </button> -->
                <a (click)="login()" class="link"> {{'signUp' | translate}} </a>
            </div>
            <!-- <div class="d-none d-lg-block login-signup" *ngIf="!authenticated">
                <a class="header-item" class="lang-ar language" (click)="sharedHelper.changeLanguage()">{{ otherLanguage }}</a>
                <a class="border-right border-secondary px-1" (click)="login()"><i class="icofont-user mr-2"></i>
      {{'login' | translate}}
    </a>
                <a class="px-1" [routerLink]="['/register/basic-info']">{{'signUp' | translate}}</a>
            </div> -->
        </div>
    </div>

    <button (click)="toggelMobileMenue(null)" type="button" class="mobile-nav-toggle d-lg-none">
    <i class="icofont-navigation-menu"> </i>
    </button>
    <nav class="mobile-nav d-lg-none">
        <ul>
            <li *ngFor="let item of headItems; index as i">
                <a (click)="toggelMobileMenue(item.link)" class="header-item">
          {{ item.desc | translate }}
        </a>
            </li>
            <li>
                <a (click)="sharedHelper.changeLanguage()" class="header-item">
          {{ otherLanguage }}
        </a>
                <app-identity></app-identity>
            </li>
        </ul>
        <div class="d-lg-none login-signup">
            <a (click)="toggelMobileMenue('/auth/login')">
        {{ "login" | translate }}
      </a>
            <a (click)="toggelMobileMenue('/register/instructions')">
        {{ "signUp" | translate }}
      </a>
        </div>
    </nav>

</header>



<header id="header" class="profile-header" *ngIf="isAuthorized">
    <div class="container">
        <div class="d-flex w-100">
            <button mat-button class="lang-ar" (click)=sharedHelper.changeLanguage()>
        {{otherLanguage}}
      </button>
            <div class="dropdown">
                <button class="dropdown-toggle" style="white-space: normal;" id="userMenu" mat-button [matMenuTriggerFor]="menu">
          {{name}}
        </button>
                <mat-menu #menu="matMenu">
                    <a class="dropdown-item" mat-menu-item routerLink="/profile">
            {{'myAccount'|translate}}
          </a>
                    <a mat-menu-item class="dropdown-item" (click)="logout()">
            {{'logout'|translate}}
          </a>
                </mat-menu>
            </div>
        </div>
    </div>
</header>