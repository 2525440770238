import { SecurityService } from 'src/app/core/services/security.service';
import { AppConfigService } from './app-config.service';
import { Injectable } from '@angular/core';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

@Injectable()
export class SignalrService {
  private _hubConnection: HubConnection;
  private SignalrHubUrl: string = '';
  constructor(
    private configurationService: AppConfigService,
    private securityService: SecurityService
  ) {
    this.SignalrHubUrl = this.configurationService.getConfiguration().authUrl;
    this.init();
  }

  public stop() {
    this._hubConnection.stop();
  }

  private init() {
    this.register();
    this.stablishConnection();
    this.registerHandlers();
  }

  private register() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(this.SignalrHubUrl + '/hub/logout', {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();
  }

  private stablishConnection() {
    this._hubConnection
      .start()
      .then(() => {
        console.log('Hub connection started');
      })
      .catch(() => {
        console.log('Error while establishing connection');
      });
  }

  private registerHandlers() {
    this._hubConnection.on('LogoutHandler', (usr) => {
      debugger;
      console.log(usr);
      let user = this.securityService.currentUser();
      if (user.sub == usr.userId) {
        this.securityService.Logoff();
      }
    });
  }
}
