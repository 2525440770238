import { UserType } from "../registration/userType.enum";

export class JwtClaims {
    amr: string[];
    AT_HASH: string;
    aud: string;
    AUTH_TIME: number
    email: string;
    EMAIL_VERIFIED: boolean
    exp: number;
    iat: number
    idp: string;
    iss: string;
    nbf: number
    nonce: string;
    PHONE_NUMBER: string;
    PHONE_NUMBER_VERIFIED: boolean
    preferred_username: string;
    role: UserType;
    S_HASH: string;
    sid: string;
    sub: string;
    UNIQUE_NAME: string;
}
