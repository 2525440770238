import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

import * as english from '../../../assets/i18n/en-US.json';
import * as arabic from '../../../assets/i18n/ar-EG.json';

export class PaginatorI18n {


  constructor(private readonly translate: TranslateService) {
  }

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = this.getTranslatedVariable('ITEMS_PER_PAGE_LABEL');
    paginatorIntl.nextPageLabel = this.getTranslatedVariable('NEXT_PAGE_LABEL');
    paginatorIntl.previousPageLabel = this.getTranslatedVariable('PREVIOUS_PAGE_LABEL');
    paginatorIntl.firstPageLabel = this.getTranslatedVariable('FIRST_PAGE_LABEL');
    paginatorIntl.lastPageLabel = this.getTranslatedVariable('LAST_PAGE_LABEL');
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
    return paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    page = page;
    if (length === 0 || pageSize === 0) {
      return this.getTranslatedVariable('RANGE_PAGE_LABEL_1') + ' ' + String(length);
    }
    length = Math.max(length, 0);
    const startIndex = (page * pageSize) + 1;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return String(startIndex) + '-' + String(endIndex) + this.getTranslatedVariable('RANGE_PAGE_LABEL_2') + String(length);
  }
  getTranslatedVariable(key) {
    return sessionStorage.getItem('lang') === 'ar-EG' ?
      arabic.default[key] :
      english.default[key];
  }
}
