export enum UserType {
  Admin = 10,
  Borrower = 20, // before 0 
	Lender = 30, // before 1
	BorrowerCorporate = 40 ,// before 2
	LenderCorporate = 50 // before 3
}
export enum LoanDuration {
  SixMonth = 1,
  TwelveMonth = 2,
  EighteenMonth = 3,
  TwentyFourMonth = 4,
  ThirtyMonth = 5,
  ThirtySixMonth = 6,
  FortyTwoMonth = 7,
  FortyEightMonth = 8,
  Borrower,
  Lender,
  BorrowerCorporate,
  LenderCorporate,
  Admin = 10,
}
export enum RiskAverage {
  High,
  Medium,
  Low
}
export enum LoanStatus {
  Pending = 10,
  Approved = 20,
  Rejected = 30,
  Completed = 40,
  InProgress = 50,
  CLosed = 60
}
