import { SignalrService } from './signalr.service';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtClaims } from '../models/identity/jwtCliams';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MainApiResult } from '../models/registration/main-response.model';

@Injectable()
export class SecurityService {
  private headers: HttpHeaders;
  private jwt = new JwtHelperService();
  private authenticationSource = new Subject<boolean>();
  authenticationChallenge$ = this.authenticationSource.asObservable();
  private authorityUrl: string;
  public IsAuthorized = false;
  userData: any;
  constructor(
    private _router: Router,
    private storage: StorageService,
    private loader: NgxUiLoaderService,
    private http: HttpClient,
    private appConfigService: AppConfigService
  ) {
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Accept', 'application/json');

    this.authorityUrl = this.appConfigService.getConfiguration().authUrl;
    this.appConfigService.getConfiguration();
    // this.appConfigService.getConfiguration().authUrl
    // conigs.default[conigs.default.activeServer].authUrl;

    if (this.storage.retrieve('authorizationDataIdToken')) {
      this.IsAuthorized = true;
      this.authenticationSource.next(true);
    } else {
      this.IsAuthorized = false;
      this.authenticationSource.next(false);
    }
    // logout when the token is removed or changes manually
    window.addEventListener('storage', (e) => {
      if (e.key === 'authorizationDataIdToken') {
        if (e.newValue !== e.oldValue) {
          this.IsAuthorized = false;
          this.authenticationSource.next(false);
          this.applyLogOff();
        }
      }
    });
  }

  public ResetAuthorizationData() {
    this.storage.clear('authorizationData');
    this.storage.clear('authorizationDataRefresh');
    this.storage.clear('authorizationDataIdToken');
    this.storage.clear('authorizationDataCode');
    this.IsAuthorized = false;
  }

  public GetToken(): any {
    return this.storage.retrieve('authorizationData');
  }
  public setAuthorizationData(access_token: string, id_token: any, code: any) {
    this.storage.store('authorizationData', access_token);
    this.storage.store('authorizationDataIdToken', id_token);
    this.storage.store('authorizationDataCode', code);
    this.IsAuthorized = true;
    window.location.href =
      this.appConfigService.getConfiguration().baseUrl + 'auth/callback';
  }

  public setRefreshTokenData(
    access_token: any,
    id_token: any,
    refresh_token: any
  ) {
    this.storage.store('authorizationData', access_token);
    this.storage.store('authorizationDataIdToken', id_token);
    this.storage.store('authorizationDataRefresh', refresh_token);
    this.storage.storeToSession('authorizationDataIdToken', id_token);
  }

  public Authorize() {
    let authorizationUrl = this.authorityUrl + '/connect/authorize';
    let client_id = this.appConfigService.getConfiguration().client_id;
    let redirect_uri =
      this.appConfigService.getConfiguration().baseUrl + 'signin-oidc';
    let response_type = 'code token id_token';
    let scope = 'openid profile offline_access loan';
    let nonce = 'N' + Math.random() + '' + Date.now();
    let state = Date.now() + '' + Math.random();
    let client_secret = this.appConfigService.getConfiguration().client_secret;

    this.storage.store('authStateControl', state);
    this.storage.store('authNonce', nonce);
    let url =
      authorizationUrl +
      '?' +
      'response_type=' +
      encodeURI(response_type) +
      '&' +
      'client_id=' +
      encodeURI(client_id) +
      '&' +
      'client_secret=' +
      encodeURI(client_secret) +
      '&' +
      'redirect_uri=' +
      encodeURI(redirect_uri) +
      '&' +
      'scope=' +
      encodeURI(scope) +
      '&' +
      'nonce=' +
      encodeURI(nonce) +
      '&' +
      'state=' +
      encodeURI(state) +
      '&' +
      'culture=' +
      sessionStorage.getItem('lang');
    window.location.href = url;
  }

  public AuthorizedCallback() {
    // this.ResetAuthorizationData();
    let hash = window.location.hash.substr(1);
    let result: any = hash
      .split('&')
      .reduce(function (result: any, item: string) {
        let parts = item.split('=');
        result[parts[0]] = parts[1];
        return result;
      }, {});

    let token = '';
    let id_token = '';
    let code = '';
    let authResponseIsValid = false;

    if (!result.error) {
      if (result.state !== this.storage.retrieve('authStateControl')) {
        console.log('AuthorizedCallback incorrect state');
      } else {
        token = result.access_token;
        id_token = result.id_token;
        code = result.code;

        let dataIdToken: any = this.getDataFromToken(id_token);
        // validate nonce
        if (dataIdToken.nonce !== this.storage.retrieve('authNonce')) {
          console.log('AuthorizedCallback incorrect nonce');
        } else {
          this.storage.clear('authNonce');
          this.storage.clear('authStateControl');

          authResponseIsValid = true;
          console.log(
            'AuthorizedCallback state and nonce validated, returning access token'
          );
        }
      }
    }
    if (authResponseIsValid) {
      this.setAuthorizationData(token, id_token, code);
    }
  }

  public Logoff() {
    this.loader.start();
    let authorizationUrl = this.authorityUrl + '/connect/endsession';
    let id_token_hint = this.storage.retrieve('authorizationDataIdToken');
    let post_logout_redirect_uri = location.origin + '/';

    let url =
      authorizationUrl +
      '?' +
      'id_token_hint=' +
      encodeURI(id_token_hint) +
      '&' +
      'post_logout_redirect_uri=' +
      encodeURI(post_logout_redirect_uri);
    console.log('Logoff()');
    this.ResetAuthorizationData();

    // emit observable
    this.authenticationSource.next(false);
    window.location.href = url;
    this.storage.clear('id');
  }

  public Handle403Error() {
    this._router.navigate(['auth/forbidden']);
  }

  refreshAccessToken(): Observable<any> {
    let authorizationUrl = this.authorityUrl + '/connect/token';
    let refreshToken = this.storage.retrieve('authorizationDataRefresh');
    const payload = new HttpParams()
      .append('grant_type', 'refresh_token')
      .append('refresh_token', refreshToken)
      .append('redirect_uri', this.appConfigService.getConfiguration().baseUrl + '/signin-oidc')
      .append('client_id', this.appConfigService.getConfiguration().client_id)
      .append('client_secret', this.appConfigService.getConfiguration().client_secret);

    return this.http.post(authorizationUrl, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  private applyLogOff() {
    let authorizationUrl = this.authorityUrl + '/connect/endsession';
    let id_token_hint = this.storage.retrieveFromSession(
      'authorizationDataIdToken'
    );
    // sessionStorage.getItem('authorizationDataIdToken');
    let post_logout_redirect_uri = location.origin + '/';

    let url =
      authorizationUrl +
      '?' +
      'id_token_hint=' +
      encodeURI(id_token_hint) +
      '&' +
      'post_logout_redirect_uri=' +
      encodeURI(post_logout_redirect_uri);
    window.location.href = url;
  }

  private urlBase64Decode(str: string) {
    let output = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw 'Illegal base64url string!';
    }

    return window.atob(output);
  }

  private getDataFromToken(token: any) {
    let data = {};
    if (typeof token !== 'undefined') {
      let encoded = token.split('.')[1];
      data = JSON.parse(this.urlBase64Decode(encoded));
    }
    return data;
  }

  public currentUser(): JwtClaims {
    debugger;
    return this.adaptJwtClaims(
      this.jwt.decodeToken(this.storage.retrieve('authorizationData'))
    );
  }

  adaptJwtClaims(decodedJwt: any): JwtClaims {
    console.log('jwt >> ', decodedJwt);

    if (!decodedJwt) return;
    const jwt = new JwtClaims();
    jwt.sub = decodedJwt.sub;
    jwt.amr = decodedJwt.amr;
    jwt.AT_HASH = decodedJwt.at_hash;
    jwt.aud = decodedJwt.aud;
    jwt.AUTH_TIME = decodedJwt.auth_time;
    jwt.email = decodedJwt.email;
    jwt.EMAIL_VERIFIED = decodedJwt.email_verified;
    jwt.exp = decodedJwt.exp;
    jwt.iat = decodedJwt.iat;
    jwt.idp = decodedJwt.idp;
    jwt.iss = decodedJwt.iss;
    jwt.nbf = decodedJwt.nbf;
    jwt.nonce = decodedJwt.nonce;
    jwt.PHONE_NUMBER = decodedJwt.phone_number;
    jwt.PHONE_NUMBER_VERIFIED = decodedJwt.phone_number_verified;
    jwt.preferred_username = decodedJwt.preferred_username;
    jwt.role = +decodedJwt.role;
    jwt.S_HASH = decodedJwt.s_hash;
    jwt.sid = decodedJwt.sid;
    jwt.sid = decodedJwt.sid;
    jwt.UNIQUE_NAME = decodedJwt.unique_name;
    return jwt;
  }
  forgotPassword(email: string) {
    return this.http.post<MainApiResult>(
      `${this.authorityUrl}/User/ForgotPasswordConfirmation`,
      {
        email: email,
      }
    );
  }
  resertPasswor(body: {
    email: any;
    Code: any;
    Password: any;
    ConfirmPassword: any;
  }) {
    return this.http.post<MainApiResult>(
      `${this.authorityUrl}/User/ResetPassword`,
      body
    );
  }
  activateAccount(body: { email: any; Token: any }) {
    return this.http.post<MainApiResult>(
      `${this.authorityUrl}/User/ActivateAccount`,
      body
    );
  }
}
